var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "contact-form",
    staticClass: "contact-us-form-modal",
    attrs: {
      "id": "contact-form",
      "backdrop": "static",
      "body-class": "p-0",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "hide-header": "",
      "scrollable": ""
    }
  }, [_c('div', {
    staticClass: "contact-us-wrapper p-4 p-md-5"
  }, [_c('a', {
    staticClass: "cursor-pointer d-inline-block position-absolute text-primary text-center line-height-40 heading-5 text-decoration-none modal-close-position-demo-form",
    style: {
      width: '40px',
      height: '40px'
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('contact-form');
      }
    }
  }, [_c('i', {
    staticClass: "cyicon-close"
  })]), _vm._v(" "), _c('div', [_c('div', {
    attrs: {
      "id": "contactForm"
    }
  })])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }