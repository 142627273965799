<template>
  <div>
    <b-modal
      id="contact-form"
      ref="contact-form"
      class="contact-us-form-modal"
      backdrop="static"
      body-class="p-0"
      hide-footer
      no-close-on-backdrop
      hide-header
      scrollable
    >
      <div class="contact-us-wrapper p-4 p-md-5">
        <a
          :style="{ width: '40px', height: '40px' }"
          class="cursor-pointer d-inline-block position-absolute text-primary text-center line-height-40 heading-5 text-decoration-none modal-close-position-demo-form"
          @click="$bvModal.hide('contact-form')"
        >
          <i class="cyicon-close"></i>
        </a>
        <div>
          <div id="contactForm"></div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'CyContactForm'
}
</script>
